import React from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import useWindowDimensions from "./GetDim";

function Projects() {
  const { height } = useWindowDimensions();
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          M Y⠀R E C E N T⠀<strong className="purple">W O R K S</strong>
        </h1>
        <p style={{ color: "white" }}>
          Coming Soon.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: height-340 }}>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
