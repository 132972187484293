import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
} from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.4em" }}>
              L E T⠀M E <span className="purple">⠀I N T R O D U C E⠀</span> MYSELF
            </h1>
            <p className="home-about-body">
              My Name Is Lexi Codes And I Am A Developer, I Have Been Developing For Years.
              <br />
              <br />I Am Good In Coding Languages Like
              <i>
                <b className="purple"> C#, Javascript, Java, Kotlin, HTML, CSS, SQL And Python. </b>
              </i>
              <br />
              <br />
              My Field of Interest's Are Developing New &nbsp;
              <i>
                <b className="purple">Web Programs, Web Builders, Apps </b> and
                Also In Areas Like{" "}
                <b className="purple">
                  Minecraft Development
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Libraries and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js And Discord.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Y O U⠀C A N⠀F I N D⠀M E⠀O N</h1>
            <p>
              F E E L⠀F R E E⠀T O⠀<span className="purple">C O N N E C T⠀</span>W I T H⠀M E
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/LexiCodesOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/LexiCodesMain"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
